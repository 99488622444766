<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
      >
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
              <label for="">{{ $t('entries') }}</label>
              <span class="ml-1 text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
              <v-select
                v-if="type === 'partner'"
                v-model="typeProfile"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="OptionTypeProfile"
                :reduce="(OptionTypeProfile) => OptionTypeProfile.value"
                :clearable="false"
                class="per-page-selector v-select-custom"
              />
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">

                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+$t('FirstName')+' '+$t('or')+' '+$t('LastName')"
                  @input="typing($event)"
                />

              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <div class="m-2">
        <b-row class="mt-2">
          <p v-if="respData.length <= 0 ">
            {{ $t('No matching records found') }}
          </p>
          <b-col
            v-for="(item,index) in respData"
            :key="index"
            cols="3"
          >
            <b-card
              :img-src="require('@/assets/images/banner/banner-11.jpg')"
              img-alt="Profile Cover Photo"
              img-top
              class="card-profile"
            >
              <div class="profile-image-wrapper">
                <div
                  v-if="type === 'member'"
                  class="profile-image p-0"
                >
                  <b-avatar
                    v-if="item.image"
                    size="114"
                    variant="light"
                    :src="item.image"
                  />
                  <b-avatar
                    v-else
                    size="114"
                    variant="light"
                    :src="getApplicationData.logo"
                  />
                </div>
                <div
                  v-if="type === 'partner' && checkArray(item.cover)"
                  class="profile-image p-0"
                >
                  <b-avatar
                    v-if="item.cover.length > 0"
                    size="114"
                    variant="light"
                    :src="item.cover[0] !== '' ? item.cover[0] : getApplicationData.logo"
                  />
                  <b-avatar
                    v-else
                    size="114"
                    variant="light"
                    :src="getApplicationData.logo"
                  />
                </div>
              </div>
              <h3>{{ item.firstName }} {{ item.lastName }}</h3>
              <!-- <h6 class="text-muted">
              Malaysia
            </h6> -->

              <b-badge
                v-if="checkArray(item.tel)"
                class="profile-badge"
                variant="light-primary"
              >
                {{ item.tel.length > 0 ? item.tel.join(',') : '' }}
              </b-badge>
              <b-badge
                v-else
                class="profile-badge"
                variant="light-primary"
              >
                {{ item.tel }}
              </b-badge>
              <hr
                class="mb-1"
              >

              <p v-if="item.wallet.length <= 0 ">
                {{ $t('No matching records found') }}
              </p>

              <!-- follower projects rank -->
              <div
                v-for="(wallet, indexWallet) in item.wallet"
                :key="indexWallet"
                class="d-flex justify-content-between align-items-center mt-1"
              >
                <div>
                  <h6 class="text-muted font-weight-bolder">
                    {{ $t('Amount') }}
                  </h6>
                  <h3 class="mb-0">
                    {{ wallet.amount }}
                  </h3>
                </div>
                <div>
                  <h6 class="text-muted font-weight-bolder">
                    {{ $t('Pending') }}
                  </h6>
                  <h3 class="mb-0">
                    {{ wallet.pending }}

                  </h3>
                </div>
                <div>
                  <h6 class="text-muted font-weight-bolder">
                    {{ $t('Service Area') }}

                  </h6>
                  <h3 class="mb-0">
                    {{ wallet.serviceAreaId }}
                  </h3>
                </div>
              </div>
            <!--/ follower projects rank -->
            </b-card>
          </b-col>
        </b-row>
      </div>

      <b-card
        no-body
        class="mb-0"
      >
        <div class="mx-2 mb-2 mt-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BImg,
  BBadge,
  BAvatar,
  BFormInput, BButton,
  BTable, BPagination, BDropdown, BOverlay,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/hero/walletBalance'
import debounce from 'debounce'

const STORE_MODULE_NAME = 'walletBalance'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BTable,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BOverlay,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      typeProfile: 'all',
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50,
      isSidebarActive: false,
      sidebarData: {},
      type: '',
      show: false,
      perPageOptions,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.data : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.max : 0
    },
    OptionTypeProfile() {
      return [
        { name: this.$t('All'.toUpperCase()), value: 'all' },
        { name: this.$t('Store Grocery'.toUpperCase()), value: 'store_grocery' },
        { name: this.$t('Store Food'.toUpperCase()), value: 'store_food' },
        { name: this.$t('Store Queue'.toUpperCase()), value: 'store_queue' },
        { name: this.$t('Driver Delivery'.toUpperCase()), value: 'driver_delivery' },
        { name: this.$t('Driver Call'.toUpperCase()), value: 'driver_call' },
        { name: this.$t('Services'.toUpperCase()), value: 'services' },
      ]
    },
    getApplicationData() {
      return localStorage.getItem('applicationData') != null
        ? JSON.parse(localStorage.getItem('applicationData'))
        : null
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.show = false
      const type = to.fullPath.split('/')
      this.type = type[type.length - 1]
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        type: this.type,
        searchQuery: this.searchQuery,
        typeProfile: this.typeProfile,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    // eslint-disable-next-line no-unused-vars
    stateArea(newCount, oldCount) {
      this.show = false
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        type: this.type,
        searchQuery: this.searchQuery,
        typeProfile: this.typeProfile,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          // console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    // eslint-disable-next-line no-unused-vars
    typeProfile(newData, oldData) {
      if (newData) {
        // eslint-disable-next-line no-unused-vars
        const obj = {
          currentPage: this.currentPage,
          pageSize: this.perPage,
          searchQuery: this.searchQuery,
          type: this.type,
          typeProfile: this.typeProfile,
        }
        this.show = true
        store
          .dispatch(`${STORE_MODULE_NAME}/get`, obj)
          .then(result => {
            this.show = false
            console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          }).catch(error => {
            this.show = false
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const type = this.$route.path.split('/')
    this.type = type[type.length - 1]
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
      searchQuery: this.searchQuery,
      type: this.type,
      typeProfile: this.typeProfile,
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    checkArray(data) {
      return Array.isArray(data)
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        type: this.type,
        typeProfile: this.typeProfile,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        type: this.type,
        typeProfile: this.typeProfile,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        type: this.type,
        typeProfile: this.typeProfile,

      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
